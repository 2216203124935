import logo from './logo.svg'
import ghImg from './assets/gh.jpg'
import './App.css'

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <p>
          Welcome! Check out some of my personal 
          <img src={logo} className='App-imgReact' alt='reactjs' /> 
          projects
        </p>
        <a
          className='App-link'
          href='https://www.addagames.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img src={ghImg} className='App-imgGh' alt='gloomhaven' /><br />
          GloomHaven Revealer App
        </a>
      </header>
    </div>
  )
}

export default App
